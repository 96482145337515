.services {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 279px) {
    header {
        text-align: center;
    }

    article.center-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #list {
        columns: 4;
        padding: 12px;
    }

    #about-list {
        padding: 0;
    }

    .about-article_cards {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .about-article_cards-reverse {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;

    }

    .contact_article_images {
        width: 80%;
        height: auto;
        transition: all.3s ease-out;
    }
}

@media only screen and (min-width:280px) and (max-width: 414px) {
    header {
        text-align: center;
    }

    article.center-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #list {
        columns: 4;
        padding: 12px;
    }

    #about-list {
        padding: 0;
    }

    .about-article_cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .about-article_cards-reverse {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;

    }

    .contact_article_images {
        width: 80%;
        height: auto;
        transition: all.3s ease-out;
    }
}

@media only screen and (min-width: 415px) and (max-width: 768px) {
    header {
        text-align: center;
    }

    article.center-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #list {
        columns: 2;
    }

    .about-article_cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 28%;
        width: 250px;
    }

    .about-article_cards-reverse {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
        width: 250px;
    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;

    }

    .contact_article_images {
        width: 100%;
        height: auto;
        object-fit: cover;
        transition: all.3s ease-out;

    }

    div.services {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1439px) {
    header {
        text-align: center;
    }

    article.center-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    #list {
        columns: 2;
    }

    .about-article_cards {
        display: flex;
        width: 90%;
        margin: 20px;
        justify-content: center;
        align-items: center;
    }

    .about-article_cards-reverse {
        display: flex;
        flex-direction: row-reverse;
        margin: 20px;
        width: 90%;
        justify-content: center;
        align-items: center;

    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 40%;

    }

    .contact_article_images {
        width: 50%;
        height: auto;
        object-fit: cover;
        transition: all.3s ease-out;
    }

}

@media (min-width: 1440px) {
    header {
        text-align: center;
    }

    article.center-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 1440px;
    }

    #list {
        columns: 2;
    }

    .about-article_cards {
        display: flex;
        width: 90%;
        margin: 20px;
        justify-content: center;
        align-items: center;
    }

    .about-article_cards-reverse {
        display: flex;
        flex-direction: row-reverse;
        margin: 20px;
        width: 90%;
        justify-content: center;
        align-items: center;

    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        width: 40%;

    }

    .contact_article_images {
        width: 50%;
        height: auto;
        object-fit: cover;
        transition: all.3s ease-out;
    }
}