.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content h1,
.content p {
  color: white;
}

.bg-image {
  height: auto;
  width: 100%;
  background-image: url("../img/services_header_img.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media only screen and (max-width: 414px),
(min-width: 415px) and (max-width: 768px),
(min-width: 769px) and (max-width: 1439px) {
  .content {
    height: 300px;
  }

  .bg-image {
    height: auto;
    width: 100%;
  }
}

@media (min-width: 1440px) {

  .content {
    height: 450px;
  }

  .bg-image {
    height: auto;
    width: 1440px;
  }
}