 @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

 * {
     box-sizing: border-box;
     font-family: 'Poppins', sans-serif;
     /* border: 1px solid black; */
     list-style-type: none;
 }

 html {
     width: 100%;

 }

 body {
     width: 100%;
     padding: 0;
     margin: 0;
     display: flex;
     justify-content: center;
     align-items: center;

 }

 #root {
     width: 100%;
 }

 @media (min-width: 320px) {
     html {
         width: 100%;
     }

 }

 @media (min-width: 1440px) {
     #root {
         width: 1440px;
         display: flex;
         justify-content: center;
         align-items: center;
     }
 }