.center-text,
#header_content {
    color: #1B5264;
}

.headers {
    padding: 15px;
}

a.text-overlay {
    border: none;
    background-color: #e1e1e1;
    font-weight: bold;
    width: 80%;
    height: 20%;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    text-decoration: none;
}

a.text-overlay:hover {
    background-color: #E1ECF9;
}

a.text-overlay:active {
    background-color: #E1ECF9;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

h1.text-overlay,
p.text-overlay {
    color: white;
    ;
}

@media only screen and (max-width: 414px) {
    .center-text>* {
        text-align: center;
    }

    .landing-container {
        width: 100%;
        height: 100%;
        background-image: url("../img/landing_header_img.png");
        background-size: cover;
        background-position: center;
        object-fit: cover;
        font-family: 'Poppins', sans-serif;
    }

    .text-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        padding: 20px 30px;
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    #header_left_container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 60%;
    }

    #header_content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #header_left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px;
    }


    #header_right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 30px;
    }

    #grid-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        padding: 40px;
    }

    img.history_image {
        width: 90%;
        height: auto;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transition: all.3s ease-out;
    }

    .text-overlay img {
        width: 25px;
        height: auto;
        margin: 10px 15px;
    }
}

@media only screen and (min-width: 280px) and (max-width: 414px) {
    .center-text>* {
        text-align: center;
    }

    .landing-container {
        width: 100%;
        height: 100%;
        background-image: url("../img/landing_header_img.png");
        background-size: cover;
        background-position: center;
        object-fit: cover;
        font-family: 'Poppins', sans-serif;
    }

    .text-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        padding: 20px 30px;
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    #header_left_container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 60%;
    }

    #header_content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #header_left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px;
    }


    #header_right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 30px;
    }

    #grid-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        padding: 40px;
    }

    img.history_image {
        width: 90%;
        height: auto;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        transition: all.3s ease-out;
    }

    .text-overlay img {
        width: 25px;
        height: auto;
        margin: 10px 15px;
    }
}

@media only screen and (min-width: 415px) and (max-width: 768px) {
    .center-text>* {
        text-align: center;
    }

    .landing-container {
        width: 100%;
        height: 100%;
        background-image: url("../img/landing_header_img.png");
        background-size: cover;
        background-position: center;
        object-fit: cover;
        font-family: 'Poppins', sans-serif;
    }

    .text-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        padding: 30px;
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    #header_left_container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 60%;
    }

    #header_content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #header_left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 30px;
    }


    #header_right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 30px;
    }

    #grid-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        padding: 40px;
    }

    button.dropbtn {
        width: 75%;
        height: 40%;
        background: #E1E1E1;
        border-radius: 5px;
    }

    p#button {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
    }

    img.history_image {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .text-overlay img {
        width: 50px;
        height: auto;
        margin: 10px 15px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1079px) {
    .center-text>* {
        text-align: center;
    }

    .landing-container {
        width: 100%;
        height: 100%;
        background-image: url("../img/landing_header_img.png");
        background-size: cover;
        background-position: center;
        object-fit: cover;
        font-family: 'Poppins', sans-serif;
    }

    .text-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        padding: 50px;
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    #header_left_container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 60%;
    }

    #header_content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #header_left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 30px;
    }


    #header_right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 30px;
    }

    #grid-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        padding: 40px;
    }

    button.dropbtn {
        width: 100%;
        padding: 0 10px;
        height: auto;
        background: #E1E1E1;
        border-radius: 5px;
    }

    p#button {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
    }

    img.history_image {
        width: 50%;
        height: auto;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .text-overlay img {
        width: 50px;
        height: auto;
        margin: 10px 15px;
    }

    a.text-overlay {
        width: 80%;
    }
}


@media only screen and (min-width: 1080px) and (max-width: 1439px) {
    .center-text>* {
        text-align: center;
    }

    .landing-container {
        width: 100%;
        height: 100%;
        background-image: url("../img/landing_header_img.png");
        background-size: cover;
        background-position: center;
        object-fit: cover;
        font-family: 'Poppins', sans-serif;
    }

    .text-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        padding: 50px;
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    #header_left_container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 60%;
    }

    #header_content {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    #header_left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 30px;
    }


    #header_right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 30px;
    }

    #grid-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        padding: 40px;
    }

    button.dropbtn {
        width: 100%;
        padding: 0 10px;
        height: auto;
        background: #E1E1E1;
        border-radius: 5px;
    }

    p#button {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
    }

    img.history_image {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .text-overlay img {
        width: 50px;
        height: auto;
        margin: 10px 15px;
    }

    a.text-overlay {
        width: 80%;
    }
}

@media (min-width: 1440px) {
    .center-text>* {
        text-align: center;
    }

    .center-text {
        width: 1440px;
    }

    .landing-container {
        width: 100%;
        height: 100%;
        background-image: url("../img/landing_header_img.png");
        background-size: cover;
        background-position: center;
        object-fit: cover;
        font-family: 'Poppins', sans-serif;
    }

    .text-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        text-align: center;
        padding: 50px;
    }

    .header {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    #header_left_container {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 60%;
    }

    #header_content {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    #header_left {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 30px;
    }


    #header_right {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 30px;
    }

    #grid-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        padding: 40px;
    }

    button {
        width: 50%;
        height: 80%;
        background: #E1E1E1;
        border-radius: 5px;
    }

    p#button {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 22px;
    }

    img.history_image {
        width: 100%;
        height: auto;
        background-size: cover;
        background-position: center;
        object-fit: cover;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .text-overlay img {
        width: 50px;
        height: auto;
        margin: 10px 37.5px;

    }

    a.text-overlay {
        width: 50%;
    }
}