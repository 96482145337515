.landing-article_cards {
    display: flex;
    flex-direction: column;
    margin: 30px;
    align-items: center;
    background-color: #E1E1E1;
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all.3s ease-out;
}

a > button.article_buttons {
    background-color: #003057;
    color: white;
    border: none;
    padding: 7.5px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
}

.center-text-landing div h1,
.center-text-landing div p,
.landing-article_cards h2,
.landing-article_cards p {
    color: #003057;
    margin: 5px;
    text-align: center;
}

@media only screen and (max-width: 414px) {
    header {
        text-align: center;
    }

    article.center-text-landing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    img.article_images {
        width: 100%;
        border-radius: 5px;
    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;

    }

    .landing-article_cards {
        margin: 5px;
        width: 40%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing-services {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .services {
        width: 100%;
    }

    .center-text-landing div p {
        display: none;
    }

    .landing-article_cards h2 {
        height: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center
    }
}

@media only screen and (min-width: 415px) and (max-width: 768px) {
    header {
        text-align: center;
    }

    article.center-text-landing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    img.article_images {
        width: 100%;
    }

    .landing-article_cards {
        margin: 20px;
        width: 40%;
        padding: 20px;
    }

    .landing-article_cards p {
        display: none;
    }

    .landing-article_cards h2 {
        width: 100%;
        text-align: center;
    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;

    }

    .services {
        width: 100%;
    }

    .landing-services {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .landing-article_cards h2 {
        height: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    header {
        text-align: center;
    }

    article.center-text-landing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    img.article_images {
        width: 100%;
    }

    .landing-article_cards {
        display: flex;
        flex-direction: column;
        margin: 20px;
        align-items: center;
        width: 40%;
        padding: 20px;
    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;

    }

    .services {
        width: 100%;
    }

    .landing-services {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .landing-article_cards h2 {
        height: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center
    }
}

@media (min-width: 1024px) {
    header {
        text-align: center;
    }

    article.center-text-landing {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    img.article_images {
        width: 100%;
    }

    .landing-article_cards {
        display: flex;
        flex-direction: column;
        margin: 20px;
        align-items: center;
        width: 385px;
        height: 666px;
        padding: 20px;
    }

    .services-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px;

    }

    .services {
        width: 100%;
    }

    .landing-services {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}