footer div h1 {
    text-align: center;
    color: white;

}

@media only screen and (max-width: 279px) {
    footer {
        display: flex;
        flex-direction: column;
        background-color: #003057;
    }

    footer>* {
        color: white;
        justify-content: center;
        margin: auto;
        align-items: center;
    }

    footer div h1 {
        text-align: center;
        color: white;

    }

    footer div p {
        text-align: left;
        font-style: italic;
        color: white;
    }

    footer #links {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #left-column,
    #right-column {
        width: 100%;
        padding: 15px;
    }


    #right-column div .contact {
        text-transform: lowercase;
    }

    #center-column {
        width: 100%;
        border-top: 1px solid #fffff7;
        border-bottom: 1px solid #fffff7;
        padding: 15px;
    }

    .logo {
        margin-right: 10px;
    }

    #credits {
        text-align: center;
        background-color: #003057;
        color: white;
        display: block;
        margin: 0;
        padding: 20px;
    }

    #center-column ul {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 111%;
        list-style-type: none;
        padding: 0;
    }

    #center-column ul li {
        text-align: center;
        color: white;
    }

    .text-design {
        text-align: center;
    }

    .text-design {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.socials {
        margin: 15px;
    }

    a.contact-footer {
        color: white;
    }
}

@media only screen and (min-width:280px) and (max-width: 590px) {
    footer {
        display: flex;
        flex-direction: column;
        background-color: #003057;
        padding: 20px;
    }

    footer>* {
        color: white;
        justify-content: center;
        margin: auto;
        align-items: center;
    }

    footer div h1 {
        text-align: center;
        color: white;

    }

    footer div p {
        text-align: left;
        font-style: italic;
        color: white;
    }

    footer #links {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #left-column,
    #right-column {
        width: 100%;
        padding: 15px;
    }


    #right-column div .contact {
        text-transform: lowercase;
    }

    #center-column {
        width: 100%;
        border-top: 1px solid #fffff7;
        border-bottom: 1px solid #fffff7;
        padding: 15px;
    }

    .logo {
        margin-right: 10px;
    }

    #credits {
        text-align: center;
        background-color: #003057;
        color: white;
        display: block;
        margin: 0;
        padding: 20px;
    }

    #center-column ul {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 111%;
        list-style-type: none;
        padding: 0;
    }

    #center-column ul li {
        text-align: center;
        color: white;
    }

    .text-design {
        text-align: center;
    }

    .text-design {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.socials {
        margin: 15px;
    }

    a.contact-footer {
        color: white;
    }
}

@media only screen and (min-width: 591px) and (max-width: 768px) {
    footer {
        display: flex;
        flex-direction: row;
        background-color: #003057;
        padding: 50px;
    }

    footer>* {
        color: white;
        justify-content: center;
        margin: auto;
        align-items: center;
    }

    footer div h1 {
        text-align: center;
    }

    footer div p {
        text-align: left;
        font-style: italic;
        color: white;
    }

    footer #links {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #left-column,
    #right-column,
    #center-column {
        width: 100%;
        margin: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }


    #right-column div .contact {
        text-transform: lowercase;
    }

    .logo {
        margin-right: 10px;
    }

    #credits {
        text-align: center;
        background-color: #003057;
        color: white;
        display: block;
        margin: 0;
        padding: 20px;
    }

    #center-column ul {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 111%;
        list-style-type: none;
        padding: 0;
    }

    #center-column ul li {
        text-align: center;
        color: white;
    }

    .text-design,
    a.contact-footer {
        white-space: pre-wrap;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text-design {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img.socials {
        margin: 7.5px;
    }

    a.contact-footer {
        color: white;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1079px) {
    footer {
        display: flex;
        flex-direction: row;
        background-color: #003057;
        padding: 50px;
    }

    footer>* {
        color: white;
        justify-content: center;
        margin: auto;
        align-items: center;
    }

    footer div h1 {
        text-align: center;
    }

    footer div p {
        text-align: left;
        font-style: italic;
        color: white;
    }

    footer #links {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #left-column,
    #right-column,
    #center-column {
        width: 100%;
        margin: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }


    #right-column div .contact {
        text-transform: lowercase;
    }

    .logo {
        margin-right: 10px;
    }

    #credits {
        text-align: center;
        background-color: #003057;
        color: white;
        display: block;
        margin: 0;
        padding: 20px;
    }

    #center-column ul {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 111%;
        list-style-type: none;
        padding: 0;
    }

    #center-column ul li {
        text-align: center;
        color: white;
    }

    .text-design,
    a.contact-footer {
        white-space: pre-wrap;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text-design {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    img.socials {
        margin: 15px;
    }

    a.contact-footer {
        color: white;
    }
}

@media only screen and (min-width: 1080px) and (max-width: 1439px) {
    footer {
        display: flex;
        flex-direction: row;
        background-color: #003057;
        padding: 50px;
    }

    footer>* {
        color: white;
        justify-content: center;
        margin: auto;
        align-items: center;
    }

    footer div h1 {
        text-align: center;
    }

    footer div p {
        text-align: left;
        font-style: italic;
        color: white;
    }

    footer #links {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #left-column,
    #right-column,
    #center-column {
        width: 100%;
        margin: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }


    #right-column div .contact {
        text-transform: lowercase;
    }

    .logo {
        margin-right: 10px;
    }

    #credits {
        text-align: center;
        background-color: #003057;
        color: white;
        display: block;
        margin: 0;
        padding: 20px;
    }

    #center-column ul {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 111%;
        list-style-type: none;
        padding: 0;
    }

    #center-column ul li {
        text-align: center;
        color: white;
    }

    .text-design,
    a.contact-footer {
        white-space: pre-wrap;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text-design {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    img.socials {
        margin: 15px;
    }

    a.contact-footer {
        color: white;
    }
}

@media (min-width: 1440px) {
    footer {
        display: flex;
        flex-direction: row;
        background-color: #003057;
        width: 1440px;
        padding: 10px;

    }

    footer>* {
        color: white;
        justify-content: center;
        margin: auto;
        align-items: center;
    }

    footer div h1 {
        text-align: center;
    }

    footer div p {
        text-align: left;
        font-style: italic;
        color: white;
    }

    footer #links {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    #left-column,
    #right-column,
    #center-column {
        width: 100%;
        margin: 10px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }


    #right-column div .contact {
        text-transform: lowercase;
    }

    .logo {
        margin-right: 10px;
    }

    #credits {
        text-align: center;
        background-color: #003057;
        color: white;
        display: block;
        margin: 0;
        padding: 20px;
        width: 1440px;
    }

    ul {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 111%;
        list-style-type: none;
        padding: 0;
    }

    .text-design {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    img.socials {
        margin: 15px;
    }

    #center-column ul li {
        color: white;
    }

    a.contact-footer {
        color: white;
    }
}