#left-box {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: all.3s ease-out;
}

input#submit {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

input#submit:hover {
    background-color: #133863;
}

input#submit:active {
    background-color: #133863;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

@media only screen and (max-width: 414px) {
    .contact-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
    }

    div.form-classes {
        width: 100%;
    }

    form {
        width: 100%;
    }

    .center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .center-column {
        display: flex;
        flex-direction: column;
    }

    input#email,
    input#name,
    select#select,
    input#phone {
        width: 100%;
        height: 54.38px;
        padding: 11px 22px;
        border-radius: 10px;
        border: 2.5px solid #003057;
    }

    div.rows-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    div.rows-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    #contacts>* {
        text-align: center;
    }

    #message,
    #submit {
        border-radius: 10px;
    }

    textarea#message {
        width: 100%;
        height: 116.54px;
        padding: 11px 22px;
        border: 2.5px solid #003057;
    }

    input#submit {
        width: 90%;
        margin-top: 10px;
    }

    #contacts {
        width: 100%;
    }

    #submit {
        height: 58.82px;
        background-color: #003057;
        color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .logo {
        margin-right: 5px;
    }

    #left-box {
        width: 85%;
        background-color: #F0F0F0;
        text-align: center;
        padding: 15px;
        border-radius: 10px;
    }

    #right-box {
        width: 100%;
        padding: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-design-contacts {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .text-design-contacts a {
        color: black;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (min-width: 415px) and (max-width: 768px) {
    .contact-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    div.form-classes {
        width: 100%;
    }

    form {
        width: 100%;
        padding: 10px;
    }

    .center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .center-column {
        display: flex;
        flex-direction: column;
    }

    input#email,
    input#name,
    select#select,
    input#phone {
        width: 100%;
        height: 54.38px;
        padding: 11px 22px;
        border-radius: 10px;
        border: 2.5px solid #003057;
    }

    div.rows-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    div.rows-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
    }

    #contacts>* {
        text-align: center;
    }

    #message,
    #submit {

        border-radius: 10px;
    }

    textarea#message {
        width: 100%;
        height: 116.54px;
        padding: 11px 22px;
        border: 2.5px solid #003057;
    }

    input#submit {
        width: 90%;
        margin-top: 10px;
    }

    #contacts {
        width: 100%;
    }

    select#select {
        width: 100%;
    }

    #submit {
        height: 58.82px;
        background-color: #003057;
        color: white;
    }

    .logo {
        margin-right: 5px;
    }

    #left-box {
        width: 100%;
        background-color: #F0F0F0;
        text-align: center;

    }

    #right-box {
        width: 100%;
        padding: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-design-contacts a {
        color: black;
    }

    .text-design-contacts {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

@media only screen and (min-width: 769px) and (max-width: 1439px) {
    .contact-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    div.form-classes {
        width: 100%;
    }

    form {
        width: 100%;
    }

    .center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .center-column {
        display: flex;
        flex-direction: column;
    }

    input#email,
    input#name,
    select#select,
    input#phone {
        width: 100%;
        height: 54.38px;
        padding: 11px 22px;
        border-radius: 10px;
        border: 2.5px solid #003057;
    }

    div.rows-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
    }

    div.rows-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
    }

    #contacts>* {
        text-align: center;
    }

    #name,
    #phone {
        margin-right: 0.75rem;
    }

    #message,
    #submit {

        border-radius: 10px;
    }

    textarea#message {
        width: 100%;
        height: 116.54px;
        padding: 11px 22px;
        border: 2.5px solid #003057;
    }

    #contacts {
        width: 100%;
    }

    #submit {
        height: 58.82px;
        background-color: #003057;
        color: white;
        width: 90%;
        margin-top: 10px;
    }

    .logo {
        margin-right: 5px;
    }

    #left-box {
        width: 50%;
        background-color: #F0F0F0;
        text-align: center;
        margin: 15px;
        padding: 15px;
        border-radius: 10px;
    }

    #right-box {
        width: 50%;
        padding: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-design-contacts a {
        color: black;
    }

    .text-design-contacts {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1440px) {
    .contact-container {
        width: 1440px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    div.form-classes {
        width: 100%;
    }

    form {
        width: 100%;
    }

    .center {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .center-column {
        display: flex;
        flex-direction: column;
    }

    input#email,
    input#name,
    select#select,
    input#phone {
        width: 100%;
        height: 54.38px;
        padding: 11px 22px;
        border-radius: 10px;
        border: 2.5px solid #003057;
    }

    div.rows-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 10px;
    }

    div.rows-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
    }

    #contacts>* {
        text-align: center;
    }

    #name,
    #phone {
        margin-right: 0.75rem;
    }

    #message,
    #submit {

        border-radius: 10px;
    }

    textarea#message {
        width: 100%;
        height: 116.54px;
        padding: 11px 22px;
        border: 2.5px solid #003057;
    }

    #contacts {
        width: 100%;
    }

    #submit {
        height: 58.82px;
        background-color: #003057;
        color: white;
        width: 90%;
        margin-top: 10px;
    }

    .logo {
        margin-right: 5px;
    }

    #left-box {
        width: 175%;
        background-color: #F0F0F0;
        text-align: center;
        margin: 15px;
        padding: 15px;
        border-radius: 10px;
    }

    #right-box {
        width: 100%;
        padding: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-design-contacts a {
        color: black;
    }

    .text-design-contacts {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}