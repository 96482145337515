h1,

h2,
h3,
p,
li,
button,
a {
    color: #003057;
}

@media only screen and (max-width: 280px) {

    h1 {
        font-size: 20px;
        margin: 0;
    }

    h2 {
        font-size: 14px;
    }

    h3 {
        font-size: 12px;
    }

    p,
    li,
    button,
    a,
    input:placeholder-shown,
    textarea:placeholder-shown,
    ul li,
    div,
    select {
        font-size: 10px;
    }

    a {
        padding: 5px 10px;
        color: #003057;
        border-radius: 5px;
    }

    a.active {
        background-color: #003057;
        color: white;
    }

}

@media only screen and (min-width: 281px) and (max-width: 414px) {

    h1 {
        font-size: 24px;
        margin: 0;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
    }

    p,
    li,
    button,
    a,
    input:placeholder-shown,
    textarea:placeholder-shown,
    ul li,
    div,
    select {
        font-size: 10px;
    }

    a {
        padding: 5px 10px;
        color: #003057;
        border-radius: 5px;
    }

    a.active {
        background-color: #003057;
        color: white;
    }

}

@media only screen and (min-width: 415px) and (max-width: 768px) {

    h1 {
        font-size: 24px;
        margin: 0;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
    }

    p,
    li,
    button,
    a,
    input:placeholder-shown,
    textarea:placeholder-shown,
    ul li,
    div,
    select {
        font-size: 14px;
    }

    a {
        padding: 5px 10px;
        color: #003057;
        border-radius: 5px;
    }

    a.active {
        background-color: #003057;
        color: white;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1439px) {

    h1 {
        font-size: 28px;
        margin: 0;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 20px;
    }

    p,
    li,
    button,
    a,
    input:placeholder-shown,
    textarea:placeholder-shown,
    ul li,
    div,
    select {
        font-size: 18px;
    }

    a {
        padding: 5px 10px;
        color: #003057;
        border-radius: 5px;
    }

    a.active {
        background-color: #003057;
        color: white;
    }

}

@media (min-width: 1440px) {

    h1 {
        font-size: 28px;
        margin: 0;
    }

    h2 {
        font-size: 22px;
    }

    h3 {
        font-size: 20px;
    }

    p,
    li,
    button,
    a,
    input:placeholder-shown,
    textarea:placeholder-shown,
    ul li,
    div,
    select {
        font-size: 18px;
    }

    a {
        padding: 5px 10px;
        color: #003057;
        border-radius: 5px;
    }

    a.active {
        background-color: #003057;
        color: white;
    }
}