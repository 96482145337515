#working-hours {
    text-align: left;
}

.fields,
.fields::selection {
    border: 2.5px solid #003057;
    padding: 5px;
}

.contact-info a {
    color: white;
}

header>h1#about {
    color: white;
}

input#about-submit.fields:hover {
    background-color: #133863;
}

input#about-submit.fields:active {
    background-color: #133863;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
}

#image-content {
    background-image: url("../img/servicespage_header_img.png");
    background-size: cover;
    background-position: center;
    object-fit: cover;}

@media only screen and (max-width: 589px) {
    section {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        padding: 10px;
    }

    form#right-section {
        padding: 15px;
    }

    header {
        text-align: center;
    }

    #image-content {
        width: 100%;
    }

    #left-section,
    #right-section,
    #about-name,
    #phones {
        width: 100%;
    }

    form#left-section {
        padding: 20px;
        color: white;
    }

    form#right-section {
        padding: 20px;
        background-color: white;
        border-radius: 5px;
        transition: all.3s ease-out;
    }

    form>*,
    #about-name,
    #phones {
        border-radius: 5px;
        margin: 10px 0;
    }

    #about-name,
    #phones {
        height: 49px;
        margin: 0;
    }

    .contact-info {
        display: flex;
        align-items: center;
    }

    .contact-info>img {
        width: 40px;
        height: auto;
        float: left;
        margin: 0 10px;
        padding: 5px;
        border-radius: 50%;
        background-color: white;
    }

    .contact-info p,
    #working-hours h2 {
        color: white;
    }

    div#name_phone {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 0;
    }

    .email-subject {
        height: 49px;
        width: 100%;
    }

    #about-name,
    #phones {
        padding: 5px;
    }

    input#name.fields {
        margin-right: 5px;
    }

    #about-message.fields {
        width: 100%;
        height: 5rem;
    }

    input#about-submit.fields {
        background-color: #003057;
        color: white;
        width: 100%;
        height: 3rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

@media only screen and (min-width: 590px) and (max-width: 768px) {
    section {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        width: 100%;
    }

    header {
        text-align: center;
    }

    header>h1#about {
        color: white;
    }

    #image-content {
        width: 100%;
    }

    #left-section,
    #right-section,
    #about-name,
    #phones {
        width: 100%;
    }

    #left-section {
        padding: 20px;
        color: white;
        column-count: 2;
    }

    form#right-section {
        padding: 20px;
        background-color: white;
        border-radius: 5px;
        transition: all.3s ease-out;
    }

    form>*,
    #about-name,
    #phones {
        border-radius: 5px;
        margin: 10px 0;
    }

    #about-name {
        margin-right: 10px;
    }

    .contact-info {
        display: flex;
        align-items: center;
        column-count: 2;
    }

    .hours {
        height: 200px;
    }

    .contact-info>img {
        width: 40px;
        height: auto;
        float: left;
        margin: 0 10px;
        padding: 5px;
        border-radius: 50%;
        background-color: white;
    }

    .contact-info p,
    #working-hours h2 {
        color: white;
    }

    div#name_phone {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 70px;
    }

    .email-subject {
        height: 49px;
        width: 100%;
    }

    #about-name,
    #phones {
        padding: 5px;
    }

    input#name.fields {
        margin-right: 5px;
    }

    #about-message.fields {
        width: 100%;
        height: 5rem;
    }

    input#about-submit.fields {
        background-color: #003057;
        color: white;
        width: 100%;
        height: 3rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

@media only screen and (min-width: 769px) and (max-width: 1439px) {
    #about-section {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: row;
        width: 100%;
    }

    header {
        text-align: center;
    }

    header>h1#about {
        color: white;
    }

    #image-content {
        width: 100%;
        padding: 15px;
    }

    #left-section,
    #right-section,
    #about-name,
    #phones {
        width: 50%;
    }

    #left-section {
        padding: 20px;
        color: white;
    }

    form#right-section {
        padding: 20px;
        background-color: white;
        border-radius: 5px;
        transition: all.3s ease-out;
    }

    form>*,
    #about-name,
    #phones {
        border-radius: 5px;
        margin: 10px 0;
    }

    #about-name {
        margin-right: 10px;
    }

    .contact-info {
        display: flex;
        align-items: center;
    }

    .contact-info>img {
        width: 40px;
        height: auto;
        float: left;
        margin: 0 10px;
        padding: 5px;
        border-radius: 50%;
        background-color: white;
    }

    .contact-info p,
    #working-hours h2 {
        color: white;
    }

    div#name_phone {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 70px;
    }

    .email-subject {
        height: 49px;
        width: 100%;
    }

    #about-name,
    #phones {
        padding: 5px;
    }

    input#name.fields {
        margin-right: 5px;
    }

    #about-message.fields {
        width: 100%;
        height: 5rem;
    }

    input#about-submit.fields {
        background-color: #003057;
        color: white;
        width: 100%;
        height: 3rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}

@media (min-width: 1440px) {
    #about-section {
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: row;
        width: 100%;
    }

    header {
        text-align: center;
    }

    header>h1#about {
        color: white;
    }

    #image-content {
        width: 1440px;
        padding: 15px;
    }

    #left-section,
    #right-section,
    #about-name,
    #phones {
        width: 50%;
    }

    #left-section {
        padding: 20px;
        color: white;
    }

    form#right-section {
        padding: 20px;
        background-color: white;
        border-radius: 5px;
        transition: all.3s ease-out;
    }

    form>*,
    #about-name,
    #phones {
        border-radius: 5px;
        margin: 10px 0;
    }

    #about-name {
        margin-right: 10px;
    }

    .contact-info {
        display: flex;
        align-items: center;
    }

    .contact-info>img {
        width: 40px;
        height: auto;
        float: left;
        margin: 0 10px;
        padding: 5px;
        border-radius: 50%;
        background-color: white;
    }

    .contact-info p,
    #working-hours h2 {
        color: white;
    }

    div#name_phone {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 70px;
    }

    .email-subject {
        height: 49px;
        width: 100%;
    }

    #about-name,
    #phones {
        padding: 5px;
    }

    input#name.fields {
        margin-right: 5px;
    }

    #about-message.fields {
        width: 100%;
        height: 5rem;
    }

    input#about-submit.fields {
        background-color: #003057;
        color: white;
        width: 100%;
        height: 3rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}